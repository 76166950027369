import Joi from "joi";

export const validateTrackingData = (body) => {
  const schema = Joi.object({
    details: Joi.string().required().messages({
      "string.base": "La description doit être une chaîne de caractères",
      "string.empty": "La description ne peut pas être vide",
      "any.required": "La description est requis",
    }),
    heur_suivi: Joi.string().required().messages({
      "string.base": "L'heure de suivi doit être une chaîne de caractères",
      "string.empty": "L'heure de suivi ne peut pas être vide",
      "any.required": "L'heure de suivi est requis",
    }),
    date_suivi: Joi.string().required().messages({
      "string.base": "La date de suivi doit être une chaîne de caractères",
      "string.empty": "La date de suivi ne peut pas être vide",
      "any.required": "La date de suivi est requis",
    }),
    agents: Joi.string().required().messages({
      "string.base": "L'agent doit être une chaîne de caractères",
      "string.empty": "L'agent ne peut pas être vide",
      "any.required": "L'agent est requis",
    }),
    chaufeur: Joi.string().required().messages({
      "string.base": "Le coursier doit être une chaîne de caractères",
      "string.empty": "Le coursier ne peut pas être vide",
      "any.required": "Le coursier est requis",
    }),
    shipping_id: Joi.number().required().messages({
      "number.base": "L'id de shipping doit être un nombre",
      "number.empty": "L'id de shipping ne peut pas être vide",
      "any.required": "L'id de shipping est requis",
    }),
    isparis: Joi.number().required().messages({
      "number.base": "Interface Paris doit être un nombre",
      "number.empty": "Interface Paris ne peut pas être vide",
      "any.required": "Interface Paris est requis",
    }),
    ismedical: Joi.number().required().messages({
      "number.base": "Interface Medical doit être un nombre",
      "number.empty": "Interface Medical ne peut pas être vide",
      "any.required": "Interface Medical est requis",
    }),
    isservice: Joi.number().required().messages({
      "number.base": "Interface Service doit être un nombre",
      "number.empty": "Interface Service ne peut pas être vide",
      "any.required": "Interface Service est requis",
    }),
    visible: Joi.number().required().messages({
      "number.base": "La visibilité doit être un nombre",
      "number.empty": "La visibilité ne peut pas être vide",
      "any.required": "La visibilité est requis",
    }),
    problem: Joi.number().required().messages({
      "number.base": "La civilité doit être un nombre",
      "number.empty": "La civilité ne peut pas être vide",
      "any.required": "La civilité est requis",
    }),
    mail: Joi.number().required().messages({
      "number.base": "La civilité doit être un nombre",
      "number.empty": "La civilité ne peut pas être vide",
      "any.required": "La civilité est requis",
    }),
    sms: Joi.number().required().messages({
      "number.base": "La civilité doit être un nombre",
      "number.empty": "La civilité ne peut pas être vide",
      "any.required": "La civilité est requis",
    }),
  }).unknown(true);

  const { error } = schema.validate(body);
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateEmailData = (body) => {
  const schema = Joi.object({
    email: Joi.string().required().messages({
      "string.base": "L'email doit être une chaîne de caractères",
      "string.empty": "L'email ne peut pas être vide",
      "any.required": "L'email est requis",
    }),
    type: Joi.string().required().required().messages({
      "string.base": "Le type doit être une chaîne de caractères",
      "string.empty": "Le type ne peut pas être vide",
      "any.required": "Le type est requis",
    }),
    shipping_id: Joi.number().required().messages({
      "number.base": "Le shipping doit être un nombre",
      "number.empty": "Le shipping ne peut pas être vide",
      "any.required": "Le shipping est requis",
    }),
  });

  const { error } = schema.validate(body);
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validatePodData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "La largeur doit être un nombre",
      "number.empty": "La largeur ne peut pas être vide",
      "any.required": "La largeur est requis",
    }),
    type: Joi.string().optional().messages({
      "string.base": "Le type doit être une chaîne de caractères",
      "string.empty": "Le type ne peut pas être vide",
      "any.required": "Le type est requis",
    }),
    signature: Joi.string().required().messages({
      "string.base": "La signature doit être une chaîne de caractères",
      "string.empty": "La signature ne peut pas être vide",
      "any.required": "La signature est requis",
    }),
    coursier: Joi.string().required().messages({
      "string.base": "Le coursier doit être une chaîne de caractères",
      "string.empty": "Le coursier ne peut pas être vide",
      "any.required": "Le coursier est requis",
    }),
    date: Joi.string().required().messages({
      "string.base": "La date doit être une chaîne de caractères",
      "string.empty": "La date ne peut pas être vide",
      "any.required": "La date est requis",
    }),
    heurs: Joi.string().required().messages({
      "string.base": "L'heure doit être une chaîne de caractères",
      "string.empty": "L'heure ne peut pas être vide",
      "any.required": "L'heure est requis",
    }),
    created_at: Joi.string().optional(),
    updated_at: Joi.string().optional(),
    need_archive: Joi.number().optional().messages({
      "number.base": "Le besoin d'archive doit être un nombre",
      "number.empty": "Le besoin d'archive ne peut pas être vide",
      "any.required": "Le besoin d'archive est requis",
    }),
  }).unknown(true);

  const { error } = schema.validate(body);
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  if (isNaN(body.shipping_id)) {
    return { valid: false, error: "shipping_id must be a number" };
  }
  return { valid: true };
};

export const validateExpeditorData = (body) => {
  const schema = Joi.object({
    fullname: Joi.string().required().messages({
      "string.base": "Le nom doit être une chaîne de caractères",
      "string.empty": "Le nom ne peut pas être vide",
      "any.required": "Le nom est requis",
    }),
    company: Joi.string().required().messages({
      "string.base":
        "Le nom de l'entreprise doit être une chaîne de caractères",
      "string.empty": "Le nom de l'entreprise ne peut pas être vide",
      "any.required": "Le nom de l'entreprise est requis",
    }),
    address1: Joi.string().required().messages({
      "string.base": "L'Adresse doit être une chaîne de caractères",
      "string.empty": "L'Adresse ne peut pas être vide",
      "any.required": "L'Adresse est requis",
    }),
    address2: Joi.any(),
    city: Joi.string().required().messages({
      "string.base": "La ville doit être une chaîne de caractères",
      "string.empty": "La ville ne peut pas être vide",
      "any.required": "La ville est requis",
    }),
    civility: Joi.string().required().messages({
      "string.base": "La civilité doit être une chaîne de caractères",
      "string.empty": "La civilité ne peut pas être vide",
      "any.required": "La civilité est requis",
    }),
    country: Joi.string().required().messages({
      "string.base": "Le pays doit être une chaîne de caractères",
      "string.empty": "Le pays ne peut pas être vide",
      "any.required": "Le pays est requis",
    }),
    phone1: Joi.string().required().messages({
      "string.base": "Le téléphone 1 doit être une chaîne de caractères",
      "string.empty": "Le téléphone 1 ne peut pas être vide",
      "any.required": "Le téléphone 1 est requis",
    }),
    phone2: Joi.any(),
    postalCode: Joi.string().required().messages({
      "string.base": "Le code postal doit être une chaîne de caractères",
      "string.empty": "Le code postal ne peut pas être vide",
      "any.required": "Le code postal est requis",
    }),
    email: Joi.string().required().messages({
      "string.base": "L'email doit être une chaîne de caractères",
      "string.empty": "L'email ne peut pas être vide",
      "any.required": "L'email est requis",
    }),
    phonecode: Joi.any(),
    type: Joi.any(),
    created_at: Joi.any(),
    updated_at: Joi.any(),
    id: Joi.number(),
    shipping_id: Joi.any(),
  });

  const { error } = schema.validate(body, {
    allowUnknown: true,
  });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateShippingDetails = (body) => {

  const shippingSchema = Joi.object({
    pickup_date: Joi.string().required().messages({
      "string.base": "La date d'enlèvement doit être une chaîne de caractères",
      "string.empty": "La date d'enlèvement ne peut pas être vide",
      "any.required": "La date d'enlèvement est requis",
    }),
    delivery_date: Joi.string().required().messages({
      "string.base": "La date de livraison doit être une chaîne de caractères",
      "string.empty": "La date de livraison ne peut pas être vide",
      "any.required": "La date de livraison est requis",
    }),
    pickup_time: Joi.string().required().messages({
      "string.base": "L'heure d'enlèvement doit être une chaîne de caractères",
      "string.empty": "L'heure d'enlèvement ne peut pas être vide",
      "any.required": "L'heure d'enlèvement est requis",
    }),
    delivery_time: Joi.string().required().messages({
      "string.base": "La date d'enlèvement doit être une chaîne de caractères",
      "string.empty": "La date d'enlèvement ne peut pas être vide",
      "any.required": "La date d'enlèvement est requis",
    }),
    code_donneur: Joi.string().allow("").optional(),
    code_receveur: Joi.string().allow("").optional(),
    customer_ref: Joi.string().allow("").optional(),
    pickup_instruction: Joi.string().allow("").optional(),
    delivery_instruction: Joi.string().allow("").optional(),
    comment: Joi.string().allow("").optional(),
    tracking_mail: Joi.string().allow("").optional(),
    transport_type: Joi.any().optional(),
  }).unknown(true);

  const packageSchema = Joi.object({
    id: Joi.number(),
    width: Joi.number().optional().messages({
      "number.base": "La largeur doit être un nombre",
      "number.empty": "La largeur ne peut pas être vide",
      "any.required": "La largeur est requis",
    }),
    length: Joi.number().optional().messages({
      "number.base": "La longueur doit être un nombre",
      "number.empty": "La longueur ne peut pas être vide",
      "any.required": "La longueur est requis",
    }),
    height: Joi.number().optional().messages({
      "number.base": "La hauteur doit être un nombre",
      "number.empty": "La hauteur ne peut pas être vide",
      "any.required": "La hauteur est requis",
    }),
    weight: Joi.number().optional().messages({
      "number.base": "Le poids doit être un nombre",
      "number.empty": "Le poids ne peut pas être vide",
      "any.required": "Le poids est requis",
    }),
    // weight_volume: Joi.number().optional().messages({
    //   "number.base": "Le poids volumétrique doit être un nombre",
    //   "number.empty": "Le poids volumétrique ne peut pas être vide",
    //   "any.required": "Le poids volumétrique est requis",
    // }),
    description: Joi.string().allow("").optional(),
    value_in_douane: Joi.string().allow("").optional(),
    statut: Joi.any().optional(),
    montant_devi: Joi.any().optional(),
    nombre_devi: Joi.any().optional(),
    created_at: Joi.string(),
    updated_at: Joi.string(),
    shipping_id: Joi.number(),
    /* documents: Joi.any(),
        valeur_assure: Joi.any(),
        additional_insurance: Joi.any() */
  }).unknown(true);

  const courseSchema = Joi.object({
    package_description: Joi.string().required().messages({
      "string.empty": "La description du colis est obligatoire"
    }),
    additional_insurance_amount: Joi.any().optional().messages({
      "string.base":
        "Le montant de l'assurance doit être une chaîne de caractères",
      "string.empty": "Le montant de l'assurance ne peut pas être vide",
      "any.required": "Le montant de l'assurance est requis",
    }),
    valeur_douane: Joi.string().allow("").optional().messages({
      "string.base": "La valeur en douane doit être une chaîne de caractères",
      "string.empty": "La valeur en douane ne peut pas être vide",
      "any.required": "La valeur en douane est requis",
    }),
    porteur: Joi.string().allow("").optional().messages({
      "string.base": "Le porteur doit être une chaîne de caractères",
      "string.empty": "Le porteur ne peut pas être vide",
      "any.required": "Le porteur est requis",
    }),

  }).unknown(true);

  const schema = Joi.object({
    shipping_id: Joi.any(),
    shipping: shippingSchema.required(),
    package: body.isExpedition
      ? packageSchema.required()
      : Joi.any().optional(),
    course: !body.isExpedition
      ? courseSchema.required().messages({
        "any.required": "Les informations de la course sont obligatoires",
      })
      : Joi.alternatives().try(courseSchema, null).optional(),
    /* package: Joi.alternatives().try(packageSchema, null).optional(),
        course: Joi.alternatives().try(courseSchema, null).optional(), */
    info_supplementaire: Joi.any().optional(),
    type_expedition: Joi.string().allow("").optional(),
  }).unknown(true);


  console.log(body.isExpedition)
  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFactureLineData = (body) => {
  const lineItemSchema = Joi.object({
    id: Joi.number().required().messages({
      "any.required": "L'identifiant est requis.",
      "number.base": "L'identifiant doit être un nombre.",
    }),
    line_type: Joi.string().valid("Facture", "Avoir").required().messages({
      "any.required": "Le type de ligne est requis.",
      "any.only": 'Le type de ligne doit être "Facture" ou "Avoir".',
    }),
    price: Joi.number().required().messages({
      "any.required": "Le prix est requis.",
      "number.base": "Le prix doit être un nombre.",
    }),
    quantity: Joi.number().required().messages({
      "any.required": "La quantité est requise.",
      "number.base": "La quantité doit être un nombre.",
    }),
    comment: Joi.string().allow("").messages({
      "string.base": "Le commentaire doit être une chaîne de caractères.",
    }),
  }).unknown(true);

  const { error } = lineItemSchema.validate(body);

  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateCarData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "Le chauffeur doit être un nombre",
      "number.empty": "Le chauffeur ne peut pas être vide",
      "any.required": "Le chauffeur est requis",
    }),
    plaque: Joi.string().required().messages({
      "string.base": "La plaque doit être une chaîne de caractères",
      "string.empty": "La plaque ne peut pas être vide",
      "any.required": "La plaque est requis",
    }),
    /* chauffeur_id: Joi.number().required().messages({
      "number.base": "Le chauffeur doit être un nombre",
      "number.empty": "Le chauffeur ne peut pas être vide",
      "any.required": "Le chauffeur est requis",
    }), */
    kmdepart: Joi.number().required().messages({
      "number.base": "Le km de départ doit être un nombre",
      "number.empty": "Le km de départ ne peut pas être vide",
      "any.required": "Le km de départ est requis",
    }),
    kmretour: Joi.number().required().messages({
      "number.base": "Le km de départ doit être un nombre",
      "number.empty": "Le km de départ ne peut pas être vide",
      "any.required": "Le km de départ est requis",
    }),
    /* telephone_societe: "",
    bip_telepage: "",
    carte_carburant: "",
    carte_bancaire: "",
    pochette_recu: 0 */
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFacturationCodeData = (body) => {
  const schema = Joi.object({
    label: Joi.string().required().messages({
      "string.base": "Le libellé doit être une chaîne de caractères",
      "string.empty": "Le libellé ne peut pas être vide",
      "any.required": "Le libellé est requis",
    }),
    description: Joi.string().required().messages({
      "string.base": "La description doit être une chaîne de caractères",
      "string.empty": "La description ne peut pas être vide",
      "any.required": "La description est requise",
    }),
    montant: Joi.number().optional().messages({
      "number.base": "Le montant doit être un nombre",
      "number.empty": "Le montant ne peut pas être vide",
      "any.required": "Le montant est requis",
    }),
    variation: Joi.number().optional().messages({
      "number.base": "La variation doit être un nombre",
      "number.empty": "La variation ne peut pas être vide",
      "any.required": "La variation est requise",
    }),
    fuel: Joi.number().optional()/* .messages({
      "any.only": "Le fuel doit être un nombre",
      "any.required": "Le fuel est requis",
    }) */,
    for_variation: Joi.number().optional(),
    Europe: Joi.number().optional(),
    Express: Joi.number().optional(),
    International: Joi.number().optional(),
    Locale: Joi.number().optional(),
    Spx_1h30: Joi.number().optional(),
    SPX_3h: Joi.number().optional(),
    National: Joi.number().optional(),
    Premium: Joi.number().optional(),
    Spx: Joi.number().optional(),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateEmployeeData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "L'id doit être un nombre",
      "number.empty": "L'id ne peut pas être vide",
      "any.required": "L'id est requis",
    }),
    nom: Joi.string().required().messages({
      "string.base": "Le nom doit être une chaîne de caractères",
      "string.empty": "Le nom ne peut pas être vide",
      "any.required": "Le nom est requis",
    }),
    prenom: Joi.string().required().messages({
      "string.base": "Le prénom doit être une chaîne de caractères",
      "string.empty": "Le prénom ne peut pas être vide",
      "any.required": "Le prénom est requis",
    }),
    telephone: Joi.string().required().messages({
      "string.base": "Le téléphone doit être une chaîne de caractères",
      "string.empty": "Le téléphone ne peut pas être vide",
      "any.required": "Le téléphone est requis",
    }),
    email: Joi.string().required().messages({
      "string.base": "L'email doit être une chaîne de caractères",
      "string.empty": "L'email ne peut pas être vide",
      "any.required": "L'email est requis",
    }),
    adress: Joi.string().required().messages({
      "string.base": "L'adresse doit être une chaîne de caractères",
      "string.empty": "L'adresse ne peut pas être vide",
      "any.required": "L'adresse est requis",
    }),
    postal_code: Joi.string().required().messages({
      "string.base": "Le code postal doit être une chaîne de caractères",
      "string.empty": "Le code postal ne peut pas être vide",
      "any.required": "Le code postal est requis",
    }),
    city: Joi.string().required().messages({
      "string.base": "La ville doit être une chaîne de caractères",
      "string.empty": "La ville ne peut pas être vide",
      "any.required": "La ville est requis",
    }),
    country: Joi.string().required().messages({
      "string.base": "Le pays doit être une chaîne de caractères",
      "string.empty": "Le pays ne peut pas être vide",
      "any.required": "Le pays est requis",
    }),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFuelData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "L'id doit être un nombre",
      "number.empty": "L'id ne peut pas être vide",
      "any.required": "L'id est requis",
    }),
    value: Joi.number().required().messages({
      "number.base": "La valeur doit être un nombre",
      "number.empty": "La valeur ne peut pas être vide",
      "any.required": "La valeur est requis",
    }),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateUserData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "L'id doit être un nombre",
      "number.empty": "L'id ne peut pas être vide",
      "any.required": "L'id est requis",
    }),
    name: Joi.string().required().messages({
      "string.base": "Le nom doit être une chaîne de caractères",
      "string.empty": "Le nom ne peut pas être vide",
      "any.required": "Le nom est requis",
    }),
    email: Joi.string().required().messages({
      "string.base": "Le prénom doit être une chaîne de caractères",
      "string.empty": "Le prénom ne peut pas être vide",
      "any.required": "Le prénom est requis",
    }),
    telephone1: Joi.string().required().messages({
      "string.base": "Le téléphone doit être une chaîne de caractères",
      "string.empty": "Le téléphone ne peut pas être vide",
      "any.required": "Le téléphone est requis",
    }),
    address1: Joi.string().required().messages({
      "string.base": "L'adresse doit être une chaîne de caractères",
      "string.empty": "L'adresse ne peut pas être vide",
      "any.required": "L'adresse est requis",
    }),

    postal_code: Joi.string().required().messages({
      "string.base": "Le code postal doit être une chaîne de caractères",
      "string.empty": "Le code postal ne peut pas être vide",
      "any.required": "Le code postal est requis",
    }),

    city: Joi.string().required().messages({
      "string.base": "La ville doit être une chaîne de caractères",
      "string.empty": "La ville ne peut pas être vide",
      "any.required": "La ville est requis",
    }),

    country: Joi.string().required().messages({
      "string.base": "Le pays doit être une chaîne de caractères",
      "string.empty": "Le pays ne peut pas être vide",
      "any.required": "Le pays est requis",
    }),

    customer_id: Joi.number().required().messages({
      "number.base": "Le client doit être une chaîne de caractères",
      "number.empty": "Le client ne peut pas être vide",
      "any.required": "Le client est requis",
    }),

    bill_center_id: Joi.array().required().messages({
      "number.base": "Le centre de facturation doit être un tableau",
      "number.empty": "Le centre de facturation ne peut pas être vide",
      "any.required": "Le centre de facturation est requis",
    }),

    /* interface: Joi.string().required().messages({
      "string.base": "L'interface doit être une chaîne de caractères",
      "string.empty": "L'interface ne peut pas être vide",
      "any.required": "L'interface est requis",
    }),

    menus: Joi.string().required().messages({
      "string.base": "Le menu doit être une chaîne de caractères",
      "string.empty": "Le menu ne peut pas être vide",
      "any.required": "Le menu est requis",
    }), */
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateActorData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "L'id doit être un nombre",
      "number.empty": "L'id ne peut pas être vide",
      "any.required": "L'id est requis",
    }),
    name: Joi.string().required().messages({
      "string.base": "Le nom doit être une chaîne de caractères",
      "string.empty": "Le nom ne peut pas être vide",
      "any.required": "Le nom est requis",
    }),
    email: Joi.string().required().messages({
      "string.base": "Le prénom doit être une chaîne de caractères",
      "string.empty": "Le prénom ne peut pas être vide",
      "any.required": "Le prénom est requis",
    }),
    telephone1: Joi.string().required().messages({
      "string.base": "Le téléphone doit être une chaîne de caractères",
      "string.empty": "Le téléphone ne peut pas être vide",
      "any.required": "Le téléphone est requis",
    }),
    address1: Joi.string().required().messages({
      "string.base": "L'adresse doit être une chaîne de caractères",
      "string.empty": "L'adresse ne peut pas être vide",
      "any.required": "L'adresse est requis",
    }),

    /* interface: Joi.string().required().messages({
      "string.base": "L'interface doit être une chaîne de caractères",
      "string.empty": "L'interface ne peut pas être vide",
      "any.required": "L'interface est requis",
    }),

    menus: Joi.string().required().messages({
      "string.base": "Le menu doit être une chaîne de caractères",
      "string.empty": "Le menu ne peut pas être vide",
      "any.required": "Le menu est requis",
    }), */
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFactureData = (body) => {
  const schema = Joi.object({
    type: Joi.string().required().messages({
      "string.base": "Le statut doit être une chaîne de caractères",
      "string.empty": "Le statut ne peut pas être vide",
      "any.required": "Le statut est requis",
    }),
    /*  bon_commande: Joi.string().optional().messages({
       "string.base": "Le bon de commande doit être une chaîne de caractères",
       "string.empty": "Le bon de commande ne peut pas être vide",
       "any.required": "Le bon de commande est requis",
     }), */
    /* status: Joi.string().required().messages({
      "string.base": "Le status doit être une chaîne de caractères",
      "string.empty": "Le status ne peut pas être vide",
      "any.required": "Le status est requis",
    }), */
    date_creation: Joi.string().required().messages({
      "string.base": "La date de création doit être une chaîne de caractères",
      "string.empty": "La date de création ne peut pas être vide",
      "any.required": "La date de création est requis",
    }),
    date_echeance: Joi.string().required().messages({
      "string.base": "La date d'échéance doit être une chaîne de caractères",
      "string.empty": "La date d'échéance ne peut pas être vide",
      "any.required": "La date d'échéance est requis",
    }),
    shippingIds: Joi.array().required().messages({
      "array.base": "La liste des expéditions doit être un tableau",
      "array.empty": "La liste des expéditions ne peut pas être vide",
      "any.required": "La liste des expéditions est requis",
    }),
    paymentMethod: Joi.string().required().messages({
      "string.base": "Le mode de règlement doit être une chaîne de caractères",
      "string.empty": "Le mode de règlement ne peut pas être vide",
      "any.required": "Le mode de règlement est requis",
    }),
    facture_reedite: Joi.when("type", {
      is: 'reediter',  // La valeur spécifique de field1 pour laquelle field2 devient obligatoire
      then: Joi.string().required(),
      otherwise: Joi.any()   // Autrement, field2 n'est pas obligatoire
    }).messages({
      "string.base": "La facture à rééditer doit être une chaîne de caractères",
      "string.empty": "La facture à rééditer ne peut pas être vide",
      "any.required": "La facture à rééditer est requis",
    })
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const validateFactureSave = (body) => {
  const schema = Joi.object({
    type: Joi.string().required().messages({
      "string.base": "Le statut doit être une chaîne de caractères",
      "string.empty": "Le statut ne peut pas être vide",
      "any.required": "Le statut est requis",
    }),
    /*  bon_commande: Joi.string().optional().messages({
       "string.base": "Le bon de commande doit être une chaîne de caractères",
       "string.empty": "Le bon de commande ne peut pas être vide",
       "any.required": "Le bon de commande est requis",
     }), */
    /* status: Joi.string().required().messages({
      "string.base": "Le status doit être une chaîne de caractères",
      "string.empty": "Le status ne peut pas être vide",
      "any.required": "Le status est requis",
    }), */

    shipping_ids: Joi.array().required().messages({
      "array.base": "La liste des expéditions doit être un tableau",
      "array.empty": "La liste des expéditions ne peut pas être vide",
      "any.required": "La liste des expéditions est requis",
    }),

    montant: Joi.number().optional().messages({
      "number.base": "Le montant doit être un nombre",
      "number.empty": "Le montant ne peut pas être vide",
      "any.required": "Le montant est requis",
    }),

    fuel: Joi.number().optional().messages({
      "number.base": "Le fuel doit être un nombre",
      "number.empty": "Le fuel ne peut pas être vide",
      "any.required": "Le fuel est requis",
    }),

    tva: Joi.number().optional().messages({
      "number.base": "La tva doit être un nombre",
      "number.empty": "La tva ne peut pas être vide",
      "any.required": "La tva est requis",
    }),

    total: Joi.number().optional().messages({
      "number.base": "Le total doit être un nombre",
      "number.empty": "Le total ne peut pas être vide",
      "any.required": "Le total est requis",
    }),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};

export const isStrongPassword = (password) => {
  // Check if password has at least 8 characters
  const hasMinimumLength = password.length >= 8;

  // Check if password has at least one lowercase letter
  const hasLowercase = /[a-z]/.test(password);

  // Check if password has at least one uppercase letter
  const hasUppercase = /[A-Z]/.test(password);

  // Check if password has at least one special character
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  if (!hasMinimumLength) {
    return {
      valid: false,
      error: "Mot de passe trop court",
    };
  } else if (!hasLowercase) {
    return {
      valid: false,
      error: "Doit contenir au moins une lettre en minuscule.",
    };
  } else if (!hasUppercase) {
    return {
      valid: false,
      error: "Doit contenir au moins une lettre en majuscule.",
    };
  } else if (!hasSpecialChar) {
    return {
      valid: false,
      error: "Doit contenir au moins un caractère spécial",
    };
  } else {
    return {
      valid: true,
      error: null,
    };
  }
};

export const calculateValidator = (body, require) => {
  console.log(body)
  let schema;

  if (require) {
    schema = Joi.object({
      expedition_mode: Joi.string().required().messages({
        "any.required": "Le mode d'expédition est requis.",
        "string.base": "Le mode d'expédition doit être un texte.",
      }),
      package_destination: Joi.number().required().messages({
        "any.required": "La destination du colis est requise.",
        "number.base": "La destination du colis doit être un nombre entier.",
      }),
      package_length: Joi.number().required().messages({
        "any.required": "La longueur du colis est requise.",
        "number.base": "La longueur du colis doit être un nombre entier.",
      }),
      package_width: Joi.number().required().messages({
        "any.required": "La largeur du colis est requise.",
        "number.base": "La largeur du colis doit être un nombre entier.",
      }),
      package_height: Joi.number().required().messages({
        "any.required": "La hauteur du colis est requise.",
        "number.base": "La hauteur du colis doit être un nombre entier.",
      }),
      package_value: Joi.number().required().messages({
        "any.required": "La valeur du colis est requise.",
        "number.base": "La valeur du colis doit être un nombre entier.",
      }),
      package_weight: Joi.number().required().messages({
        "any.required": "Le poids du colis est requis.",
        "number.base": "Le poids du colis doit être un nombre entier.",
      }),

    });
  }
  else {
    schema = Joi.object({
      expedition_mode: Joi.string().required().messages({
        "any.required": "Le mode d'expédition est requis.",
        "string.base": "Le mode d'expédition doit être un texte.",
      }),
      package_destination: Joi.number().required().messages({
        "any.required": "La destination du colis est requise.",
        "number.base": "La destination du colis doit être un nombre entier.",
      }),
      package_length: Joi.number().required().messages({
        "any.required": "La longueur du colis est requise.",
        "number.base": "La longueur du colis doit être un nombre entier.",
      }),
      package_width: Joi.number().required().messages({
        "any.required": "La largeur du colis est requise.",
        "number.base": "La largeur du colis doit être un nombre entier.",
      }),
      package_height: Joi.number().required().messages({
        "any.required": "La hauteur du colis est requise.",
        "number.base": "La hauteur du colis doit être un nombre entier.",
      }),
      package_weight: Joi.number().required().messages({
        "any.required": "Le poids du colis est requis.",
        "number.base": "Le poids du colis doit être un nombre entier.",
      }),

    });
  }


  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    // return { valid: false, error: error.details[0].message };
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};



export const validateTransportTypesData = (body) => {
  const schema = Joi.object({
    id: Joi.number().optional().messages({
      "number.base": "L'id doit être un nombre",
      "number.empty": "L'id ne peut pas être vide",
      "any.required": "L'id est requis",
    }),
    label: Joi.string().required().messages({
      "number.base": "Le libellé doit être un nombre",
      "number.empty": "Le libellé ne peut pas être vide",
      "any.required": "Le libellé est requis",
    }),
  });

  const { error } = schema.validate(body, { allowUnknown: true });
  if (error) {
    return { valid: false, error: error.details[0].message };
  }
  return { valid: true };
};




const customMessages = {
  'string.base': '{{#label}} doit être une chaîne de caractères',
  'string.uri': '{{#label}} doit être une URL valide',
  'string.empty': '{#label} ne doit pas être vide',
  'string.email': 'Veuillez fournir une adresse email valide pour {{#label}}',
  'string.required': '{{#label}} est obligatoire',
  'string.length': '{{#label}} doit contenir contient {{#limit}} de caractère(s)',
  'string.min': '{{#label}} doit comporter au minimum {{#limit}} caractère(s)',
  'any.required': '{{#label}} est obligatoire',
  'string.isoDate': '{{#label}} doit être une date valide au format ISO',
  'string.valid': '{{#label}} n\'a pas une valeur valide',
  'boolean.base': '{{#label}} doit être un boolean',
  'number.base': '{{#label}} doit être un nombre',
  'object.base': '{{#label}} doit être un objet',

};

const returnError = (response) => {
  const { error } = response;
  if (error) {
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;


    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
}


export const InformationValidator = (data) => {
  const schema = Joi.object({
    id: Joi.any().optional().label('Identifiant'),
    with_image: Joi.object({
      label: Joi.string().required().label('Avec Image'),
      value: Joi.number().required().label('Avec Image'),
    }),
    image_url: Joi.any().when("with_image.value", {
      is: 1,
      then: Joi.object().required(),
      otherwise: Joi.any().optional()
    }).label('Image'),
    libele: Joi.string().required().label('Libellé'),
    contenus: Joi.string().required().label('Contenus'),
    active_baniere: Joi.object({
      label: Joi.string().required().label('Banière'),
      value: Joi.number().required().label('Banière'),
    }),


  }).messages(customMessages)
    .unknown(false) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data))

}



export const InformationUpdateValidator = (data) => {
  const schema = Joi.object({
    id: Joi.any().optional().label('Identifiant'),
    with_image: Joi.object({
      label: Joi.string().required().label('Avec Image'),
      value: Joi.number().required().label('Avec Image'),
    }),
    image_url: Joi.any().when("with_image.value", {
      is: 1,
      then: Joi.any().required(),
      otherwise: Joi.any().optional()
    }).label('Image'),
    libele: Joi.string().required().label('Libellé'),
    contenus: Joi.string().required().label('Contenus'),
    active_baniere: Joi.object({
      label: Joi.string().required().label('Banière'),
      value: Joi.number().required().label('Banière'),
    }),


  }).messages(customMessages)
    .unknown(false) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });

  return returnError(schema.validate(data))

}