import React, { useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import ModalComponent from "../Utils/Modal/Index";
import useFormValues from "../../hooks/useFormValues";
import adminService from "../../services/admin/adminService";
import Select from "react-select";
import UsersSelector from "../Utils/Selectors/UsersSelector";
import { ToastContainer, toast } from "react-toastify";
import VehiculeService from "../../services/vehicule/vehiculeService.js";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";

const FormComponent = ({ modalFormContent, newData, editable, onClose }) => {
    const [VehiculeData, SetVehiculeData] = useState(
        modalFormContent ? modalFormContent : {}
    );


    const setFormValue = (key, value) => {
        SetVehiculeData((d) => ({
            ...d,
            [key]: value,
        }));
    };


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [makeUpdate, setmakeUpdate] = useState(false);

    const onSubmit = async (e) => {

        try {
            e.preventDefault();
            setIsSubmitting(true);


            const toasId = toast.loading("Soumission du formulaire", {
                position: "top-right",
                theme: "light",
            });


            const { data } = makeUpdate
                ? await VehiculeService.updateVehicule(VehiculeData)
                : await VehiculeService.createVehicule(VehiculeData);

            if (data.success) {
                onClose();
                toast.update(toasId, {
                    render: data.message,
                    type: "success",
                    isLoading: false,
                    autoClose: 500,
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
            } else if (data.is_done) {
                onClose();
                toast.update(toasId, {
                    render: "Le Formulaire a été soumise avec succès!",
                    type: "success",
                    isLoading: false,
                    autoClose: 500,
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const DesabledInput = () => {
        return newData || makeUpdate ? false : true;
    };



    return (
        <div className="p-3">
            {!newData && (
                <div className={"flex my-4"}>
                    <button
                        type="submit"
                        className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                        onClick={() => setmakeUpdate(DesabledInput())}
                    >
                        <span className={"fa fa-edit"}></span>
                    </button>
                </div>
            )}

            <form onSubmit={onSubmit}>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div className="text-left">
                        <label
                            for="plaque"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            Plaque
                        </label>
                        <input
                            disabled={DesabledInput()}
                            type="text"
                            id="plaque"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            required
                            value={VehiculeData.plaque}
                            onChange={(e) =>
                                setFormValue("plaque", e.target.value)
                            }
                        />
                    </div>
                    <div className="text-left">
                        <label
                            for="compta_responsible"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            KM DE DEPART
                        </label>
                        <input
                            disabled={DesabledInput()}
                            type="text"
                            id="compta_responsible"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            value={VehiculeData.kmdepart}
                            onChange={(e) =>
                                setFormValue("kmdepart", e.target.value)
                            }
                        />
                    </div>

                    <div className="text-left">
                        <label
                            for="kmretour"
                            className="block mb-2 text-sm font-medium text-gray-900 "
                        >
                            KM DE RETOUR
                        </label>
                        <input
                            disabled={DesabledInput()}
                            type="text"
                            id="kmretour"
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                            value={VehiculeData.kmretour}
                            onChange={(e) =>
                                setFormValue("kmretour", e.target.value)
                            }
                        />
                    </div>

                    <div className="text-left">
                        <UsersSelector
                            label={"Attribuer un chauffeur"}
                            profil={"chauffeur"}
                            selectedValue={VehiculeData.user_id}
                            onSelect={(val) => {
                                setFormValue("user_id", val.user_id);
                            }}
                        />
                    </div>

                    <div className="text-left">
                        <UsersSelector
                            label={"Attribuer un sédentaire"}
                            profil={"sedentaire"}
                            selectedValue={VehiculeData.user_id}
                            onSelect={(val) => {
                                setFormValue("user_id", val.user_id);
                            }}
                        />
                    </div>

                    <div className="text-left">
                        <UsersSelector
                            label={"Attribuer un voyageur"}
                            profil={"voyageur"}
                            selectedValue={VehiculeData.user_id}
                            onSelect={(val) => {
                                setFormValue("user_id", val.user_id);
                            }}
                        />
                    </div>





                </div>

                <div className="flex justify-center">
                    {(editable || makeUpdate) && (
                        <button
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                            disabled={isSubmitting}
                        >
                            Modifier
                        </button>
                    )}

                    {newData && (
                        <button
                            type="submit"
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                            disabled={isSubmitting}
                        >
                            Enregistrer
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default function VehiculeAdminFormComponent({
    withoutModal,
    modalFormContent,
    editable,
    dataType,
    Fermer,
}) {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <>
            {withoutModal ? (
                <FormComponent
                    modalFormContent={modalFormContent}
                    editable={editable}
                    dataType={dataType}
                    onClose={Fermer}
                />
            ) : (
                <>
                    <div
                        className="float-right btn btn-primary btn-rounded"
                        onClick={onOpen}
                        editable={false}
                    >
                        <PlusIcon  className={"h-4 w-4 m-3 text-xl font-bold "}/>
                    </div>
                    <ModalComponent
                        isOpen={isOpen}
                        onClose={onClose}
                        title="Ajouter un Véhicule"
                    >
                        <FormComponent
                            modalFormContent={null}
                            newData={true}
                            dataType={dataType}
                            onClose={onClose}
                        />
                    </ModalComponent>
                </>
            )}
        </>
    );
}
