import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useApi } from "../../../hooks/useRequestLoader";
import transportService from "../../../services/transportTypes"

export default function TransportTypeSelector({ value, onSelect, isDisabled }) {
  const options = [
    { value: "exw", label: "EXW " },
    { value: "dap", label: "DAP  " },
    { value: "ddp", label: "DDP " },
  ];

  const selectedValue = options.find((c) => c?.value == value);

  return (
    <div className="mb-3 form-group">
      <label htmlFor="field6">Types de transport</label>
      <div class="form-group">
        <Select
          placeholder={""}
          className={"text-base "}
          options={options}
          value={selectedValue}
          onChange={(v) => {
            onSelect(v);
          }}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
}

export const IncotermSelector = ({ value, onSelect, isDisabled }) => {

  const options = [
    { value: "exw", label: "EXW" },
    { value: "dap", label: "DAP " },
    { value: "ddp", label: "DDP " },
  ];

  const selectedValue = options.find((c) => c?.value === value);

  return (
    <div className="mb-3 form-group">
      <label htmlFor="myInput3 m-b-2" className="uppercase">Choix incoterm</label>
      <div class="form-group">
        <Select
          placeholder={"choix incoterm".toUpperCase()}
          className={"text-sm "}
          options={options}
          value={selectedValue}
          onChange={(v) => {
            onSelect(v);
          }}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export const CivilitySelector = ({ value, onSelect, isDisabled }) => {
  const options = [
    { value: "Mr", label: "Mr" },
    { value: "Mme", label: "Mme" },
  ];

  const selectedValue = options.find((c) => c?.value == value);

  return (
    <div className="mb-3 form-group">
      <label htmlFor="myInput3 m-b-2" className="uppercase">Civilité</label>
      <div class="form-group">
        <Select
          placeholder={"civilité".toUpperCase()}
          className={"text-sm "}
          options={options}
          value={selectedValue}
          onChange={(v) => {
            onSelect(v);
          }}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export const TransportTypeSelectorDeux = ({ value, onSelect, isDisabled }) => {
  const { items, isLoading } = useApi({ request: transportService.getOpsLists })

  const lists = items?.map(item => ({
    //...item,
    label: item.label?.toUpperCase(),
    value: item.label?.toUpperCase(),
  })) || []

  const options = [{ label: "CHOIX DU TYPE DE TRANSPORT", value: "" }, ...lists] /* [
    { value: "vente client", label: "vente client ".toUpperCase() },
    {
      value: "transfert inter boutique",
      label: "transfert inter boutique".toUpperCase(),
    },
    { value: "retour client", label: "retour client".toUpperCase() },
    { value: "algo", label: "algo".toUpperCase() },
    { value: "jed yard", label: "JED YARD".toUpperCase() },
  ]; */

  const selectedValue = options.find((c) => c?.value === value);

  const placeholder = isLoading ? "chargement en cours..." : "choix type de tranport"

  return (
    <div className="mb-3 form-group">
      <label htmlFor="field6" className="uppercase">Types de transport</label>
      <div class="form-group">
        <Select
          placeholder={placeholder.toUpperCase()}
          className={"text-sm "}
          options={options}
          value={selectedValue}
          onChange={(v) => {
            onSelect(v);
          }}
        //isDisabled={isDisabled || isLoading}
        />
      </div>
    </div>
  );
};
