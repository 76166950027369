import React, { useEffect, useState } from "react";
import adminService from "../../services/admin/adminService";
import { useParams } from "react-router-dom";
import { getUniqueItemsByKey } from "../../utils";
import { toast } from "react-toastify";
import SuiviLists from "./SuiviLists";
import AddTrackingForm from "./Form";
import CommentaireComponent from "../detailsExpedition/CommentaireComponent";
import { trierTableauParDateEtHeureSuivi } from "../../utils/Functions/otherFunctions";
import { useAppContext } from "../../context";

function AddTrackingComponent({ expedition, showComment = false, setExpeditionData = false }) {
    const [suivis, setSuivis] = useState([])

    useEffect(() => {
        if (expedition) {
            setSuivis(expedition.suivis)
        }
    }, [expedition])

    const onAddSubmit = (item) => {
        if (item) {
            let newValue = [item, ...suivis]
            let value = trierTableauParDateEtHeureSuivi(newValue)
            console.log(value)
            setSuivis([...value])
        }
    }


    const toggleActif = async (item, value) => {
        try {


            adminService.editTrackingState({ id: item.id, visible: value === true ? 1 : 0 }).then(
                (data) => {
                    console.log(data)
                    let newData = [...suivis]

                    newData.forEach((el, index) => {
                        if (el.id === item.id) {
                            newData[index] = { ...item, visible: value === true ? 1 : 0 }
                            setSuivis([...newData])
                        }
                    });

                    toast.success(`Tracking modifi`, {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                },
                (error) => {
                    console.log(error)
                }
            )

        } catch (error) {
            console.error(error);
            toast.error(`Une erreur est survenue`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <div className="card-box">
            <TrackingFormWithTemplates
                expedition={expedition}
                /* formValue={formValue}
                setFormValue={setFormValue} */
                onAddSubmit={onAddSubmit}
                showComment={showComment}
                setExpeditionData={setExpeditionData}
            />

            <SuiviLists items={[...suivis]} onToggleActive={toggleActif} />
        </div>
    );
}

export const TrackingFormWithTemplates = ({ expedition, onAddSubmit, onClose = false, updatedSelectedData, setExpeditionData, showComment = false }) => {
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { isAuth, setIsAuth } = useAppContext();

    const [templates, setTemplates] = useState([]);
    const [page, setPage] = useState(1); 
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [formValue, setFormValue] = useState({});

    const shipping_id = expedition?.id

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                setIsLoading(true)
                const params = {
                    type: isAuth?.spaceName,
                    page
                }
                const { data } = await adminService.getOpsTracking(params);
                if (data.success) {
                    setTemplates(d => getUniqueItemsByKey([...d, ...data.data.data], "id"));

                    const lastPage = data.data.last_page;
                    if (page < lastPage) {
                        setPage(page + 1);
                    }
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchTemplates()
    }, [page])


    const onTemplateSelect = (t) => {
        setSelectedTemplate(t)
        setFormValue({ details: t.details, isdriver: t.isdriver })
    }

    const results = templates.filter(t => {
        const regExp = new RegExp(search, "gi");
        return t.details.match(regExp)
    }
    )


    useEffect(() => {
        console.log('la team ', expedition)
    }, [])


    return (
        <div className="grid-cols-12 space-y-3 md:grid md:gap-3 md:space-y-0 ">
            <div className="col-span-7">
                <h3>Suivi tracking</h3>
                <AddTrackingForm
                    data={formValue}
                    expedition={expedition}
                    onSubmit={onAddSubmit}
                    template={selectedTemplate}
                    onClose={onClose}
                />
            </div>

            <div className="col-span-5">

                <div className="flex items-center gap-4 mb-2">
                    <h4 className="text-lg font-semibold">Templates</h4>
                    <div className="flex-1">
                        <input
                            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 p-2.5"
                            type="search"
                            placeholder="Recherche"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>


                <div className="row">
                    <div
                        className="h-[270px] overflow-y-scroll px-1"
                    >
                        {results && results.map((item, index) => (
                            <div
                                className="px-1 mb-1 cursor-pointer row bg-gray-50"
                                style={{
                                    maxHeight: "50px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                                key={index}
                                onClick={() => { onTemplateSelect(item) }}
                            >
                                <div className="my-1">
                                    <p
                                        className={"my-1"}
                                        style={{
                                            fontSize: 14,
                                            paddingInline: 0,
                                            fontWeight: 400,
                                            textDecoration: "none",
                                        }}
                                    >
                                        {item.details}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pt-10 mt-10">
                        <CommentaireComponent withLabel={true} value={expedition} setExpeditionData={setExpeditionData} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddTrackingComponent;
