import { create } from "zustand";


const InitialUserData = {
    civility: "M",
    company: "",
    fullname: "",
    lastName: "",
    firstName: "",
    address1: "",
    address2: "",
    country: "fr",
    postalCode: "",
    city: "",
    phonecode: "",
    phone1: "+33",
    phone2: "+33",
    email: "",
}

const InitialPackagesData = {
    length: 0,
    width: 0,
    height: 0,
    weight: 1,
    weight_volume: "",
    description: "",
    incoterm: "exw",
    value_in_douane: "",
    devises: "eur",
    documents: [],
    ref: "",
}
const InitialCoursePackage = {
    package_description: "",
    additional_insurance_amount: "",
    additional_insurance: "",
    documents: [{}],
    created_at: "",
    valeur_douane: "",
    porteur: "",
}

export const ShippingDetails = create((set) => ({

    details: {
        expeditor: { ...InitialUserData },
        receiver: { ...InitialUserData },
        packages: [InitialPackagesData],
        coursePackages: [InitialCoursePackage],
    },
    setDetails: (value) => set((state) => ({
        details: {
            ...state.details,
            ...value
        }
    })),
    updateDetails: (value, keys) => set((state) => ({
        details: {
            ...state.details,
            [keys]: value
        },
    })),

    resetUserData: () => set((state) => ({
        user: {
            expeditor: { ...InitialUserData },
            receiver: { ...InitialUserData }
        }
    })),

}))