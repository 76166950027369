import React, { useEffect, useState } from "react";
import Select from "react-select";

const IncotermSelector = ({ showLabel = true, value, onChange }) => {
  const [types, setTypes] = useState([
    { value: "exw", label: "EXW" },
    { value: "dap", label: "DAP" },
    { value: "ddp", label: "DDP" },
  ]);

  const values = types.find((t) => t.value === value);

  return (
    <div>
      {showLabel && (
        <label
          for="types"
          className="block mt-1 text-sm font-medium text-gray-900 form-label"
        >
          CHOIX INCOTERM
        </label>
      )}
      <Select
        options={types}
        value={values}
        onChange={(v) => onChange(v.value)}
        className={"text-base w-100"}
      />
    </div>
  );
};

export default IncotermSelector;
