import React, { useEffect } from "react";
import { formatDefaultDate } from "../../../utils";
import { chekText } from "../../../utils/Functions/otherFunctions";
import { Paragraphe } from "../../Utils/UI/PragrapheComponent";
import { getCountryNameOrCode } from "../../../utils/const/countriesConstant";



function DetailAgentComponent({ agent, details, instruction, date, heure }) {

    useEffect(() => {
        console.log('details client', details)
    }, [])

    return (
        <>

            {details && (
                <div className="h-full px-2 uppercase bg-white rounded-md">
                    <div className="text-center  flex items-center justify-center text-base font-semibold h-[40px] py-6">
                        {agent === "expediteur"
                            ? "EXPEDITEUR"
                            : "DESTINATAIRE"}
                    </div>
                    <hr />
                    <div className="grid-cols-1 gap-6 space-y-1 text-base font-normal md:grid md:grid-cols-2 md:space-y-0">
                        <div className="">
                            <Paragraphe label={'Entreprise'} value={details.company} />
                            <Paragraphe label={'Nom et prenom'} value={details.fullname} />
                            <Paragraphe label={'Adresse'} value={details.address1} />
                            <Paragraphe label={'Code postal'} value={details.postalCode} />
                            <Paragraphe label={'Ville'} value={details.city} />

                        </div>
                        <div className="h-full max-w-full overflow-x-auto">

                            <div class=" ">
                                <Paragraphe label={'Pays'} value={getCountryNameOrCode(details.country)} />
                                <Paragraphe label={'Téléphone'} value={details.phone1} />
                                <Paragraphe label={'Email'} value={details.email} mail={true} />
                                <div className="my-2 ">
                                    <span className="text-sm font-semibold"> {agent === "expediteur"
                                        ? "ENLEVEMENT"
                                        : "LIVRAISON"}{" "} :  </span>
                                    <span className="ml-1 text-sm font-normal"> LE : {formatDefaultDate(date)} à {heure}</span>
                                </div>

                                <div className="my-2 ">
                                    <span className="text-sm font-semibold"> INSTRUCTIONS{" "}
                                        {agent === "expediteur"
                                            ? "D'ENLEVEMENT"
                                            : "DE LIVRAISON"} :  </span>
                                    <span className="block ml-1 text-sm font-normal">  {instruction}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}</>
    );
}

export default DetailAgentComponent;
