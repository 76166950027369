import React, { useEffect } from "react";
import DetailsColisComponent from "../../components/detailsExpedition/DetailsColisComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useAppContext } from "../../context";
import { ShippingDetail } from "../../components/shippingDetails";

const ExpeditionDetailsPage = React.memo((props) => {
  const { ExpeditionType, identifiant } = useParams();
  const [espace, setEspace] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { isAuth, setIsAuth } = useAppContext();

  useEffect(() => {
    if (window.location.pathname.split("/")[1] !== "client") {
      if (
        ExpeditionType === undefined ||
        identifiant === undefined ||
        !parseInt(identifiant)
      ) {
        let lien = window.location.pathname.split("/");
        lien.pop();
        if (lien[0].toLowerCase() !== "client") {
          lien = lien.join("/");
          window.location.href = lien;
        }
      }
    }

    // let pathName = window.location.pathname.split("/")[2];
    // setEspace(pathName);
  }, []);

  return (
    <>
      <div className="px-2 :md:px-0">

        <DetailsColisComponent
          expeditionId={identifiant}
          expeditionSpace={isAuth?.spaceName}
          key={pathname}
        
        /> 

        {/* <ShippingDetail
          expeditionId={identifiant}
          expeditionSpace={isAuth?.spaceName}
        /> */}

      </div>
    </>
  );
});

export default ExpeditionDetailsPage;
