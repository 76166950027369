export const getValue = (value1, value2) =>
  value1 !== undefined && value1 !== ""
    ? value1
    : value2 !== undefined && value2 !== ""
      ? value2
      : "";


// Fonction utilitaire pour extraire le prénom et le nom
export const parseName = (fullname, name,data) => {
  const combinedName = fullname || name || "";
  const [firstName, lastName] = combinedName.split(" ");
  return {
    firstName: firstName || data?.firstName,
    lastName: lastName && lastName !== "undefined" ? lastName : data?.lastName,
  };
};