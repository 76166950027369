import React, { useCallback, useState } from "react";
import useDisclosure from "../../../hooks/useDisclosure";
//import Form from './Form';
import useAdminLists from "../../../hooks/useAdminLists";
import { formatDefaultDate } from "../../../utils/moment";
import { TableLoader } from "../../Utils/Loader";
import { ActorModal } from "../../Users/UsersSimpleForm";
import { SwitchComponent } from "../../Utils/Index";
import { userService } from "../../../services/user/UserService";
import { toast } from "react-toastify";
import PaginationComponent from "../../paginationComponent/PaginationComponent";
import useTableFilter from "../../../hooks/useTableFilter";
import { TbArrowsUpDown } from "react-icons/tb";
import CarAttribution from "../Cars/CarAttribution";
import OptionsSelector from "../../Utils/Selectors/Index";
import { stateOptions } from "../../../utils/const/data";
import { FunnelIcon, PlusIcon } from "@heroicons/react/24/outline";
import { TableComponent } from "../../TableauComponent";
import { useAppContext } from "../../../context";
import clsx from "clsx";
import { TableDataStore } from "../../../states/tableData";

export const OpsView = () => {
  return <ActorView type="ops" title="Liste des opérationnels" />;
};

export const AdminView = () => {
  return <ActorView type="admin" title="Liste des administrateurs" />;
};

export const DriversView = () => {
  return <ActorView type="chauffeur" title="Liste des chauffeurs" />;
};

const ActorView = ({ type, title }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showFilter, setShowFilter] = useState(true)
  const { isAuth, setIsAuth } = useAppContext();

  const {
    isOpen: isDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
    onOpen: onDeleteConfirmOpen,
  } = useDisclosure();

  const { adminData, metadata, setMetadata, setPage, setPerPage, isLoading, onSubmit, search, setSearch } = useAdminLists({ type });
  const [selectedItem, setSelectedItem] = useState(null);

  const onItemSelected = (item, action) => {
    setSelectedItem(item);
    if (action === "edit") {
      onOpen();
    } else {
      onDeleteConfirmOpen();
    }
  };

  const toggleActif = useCallback(async (item, value) => {
    try {
      //onSubmit({ ...item, actif: value ? 1 : 0 })
      const request = value ? userService.activer : userService.desactiver;
      const { data } = await request({ id: item.id });
      if (data.success) {
        onSubmit({ ...item, is_actif: value ? 1 : 0 });
      } else {
        toast.error(`Une erreur est survenue`, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(`Une erreur est survenue`, {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [onSubmit]);

  const query = metadata.filter;

  const setFilterValue = (key, value) => {
    setMetadata(m => ({
      ...m,
      filter: {
        ...m.filter,
        [key]: value
      }
    }))
  }


  /*   const onCarAttributed = useCallback(
      (car) => {
  
      },
      [],
    ) */


  return (
    <div>
      <div className="">
        <div className={"flex justify-between items-center"}>
          <div>
            <h4 className="block uppercase page-title">{title}</h4>
          </div>


          <div className={"flex gap-3"}>
            <div
              className="p-1 text-white rounded-full bg-admin w-fit"
              onClick={onOpen}
            >
              <PlusIcon className={"h-4 w-4 m-2 text-xl font-bold "} />
            </div>
            <div className="rounded-full cursor-pointer w-fit bg-admin" style={{
              color: "#fff",
            }}>
              <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
            </div>
          </div>


        </div>

        {/* <div className="flex justify-end">
          <div className="rounded-full cursor-pointer w-fit bg-admin" style={{
            color: "#fff",
          }}>
            <FunnelIcon onClick={() => setShowFilter(!showFilter)} className={"h-4 w-4 m-3 text-xl font-bold  "} />
          </div>
        </div> */}
        <section
          className={clsx(
            "transition-all duration-700 ease-in-out",
            { "max-h-[800px] opacity-100  p-3 ": showFilter, "max-h-0 opacity-0": !showFilter }
          )}>
          <div className={"md:w-1/2"}>
            <div className="items-center grid-cols-2 gap-3 space-y-2 md:grid md:space-y-0">
              <div className="w-full">
                <label
                  for="types"
                  className="block text-sm font-medium text-gray-900"
                >
                  Recherche
                </label>
                <input
                  className="w-full px-4 py-2 bg-white border border-gray-500 rounded-lg outline-none form-control"
                  type="search"
                  placeholder="Recherche"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="w-full ">
                <OptionsSelector
                  label={"Statut"}
                  options={stateOptions}
                  value={query.state}
                  defaultValue="all"
                  onChange={v => {
                    //console.log({ v })
                    setFilterValue("state", v.value)
                  }}
                />
              </div>
            </div>

          </div>
        </section>
      </div>

      <div className="py-3">
        {adminData.total ?
          <PaginationComponent
            page={metadata.page}
            handlePageClick={setPage}
            totalItems={adminData.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={setPerPage}
          /> : null}
      </div>

      <Lists
        data={adminData.data}
        onSelect={onItemSelected}
        isLoading={isLoading}
        onToggleActive={toggleActif}
        type={type}
      //onCarAttributed={onCarAttributed}
      />
      <div className="py-3">
        {adminData.total ?
          <PaginationComponent
            page={metadata.page}
            element="pagination"
            handlePageClick={setPage}
            totalItems={adminData.total}
            perPageItems={metadata.perPage}
            setnumberPerPage={setPerPage}
          /> : null}
      </div>

      <ActorModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setSelectedItem(null);
        }}
        onSubmit={onSubmit}
        selectedData={selectedItem}
        type={type}
      />
    </div >
  );
};

const Lists = ({ type, data, onSelect, /* onCarAttributed, */ isLoading, onToggleActive }) => {
  const { results, onTableHeaderClick } = useTableFilter({ items: data });

  const dataList = TableDataStore((state) => state.dataList);
  const setDataList = TableDataStore((state) => state.setDataList);
  const updateData = TableDataStore((state) => state.updateData);
  const deleteData = TableDataStore((state) => state.deleteData);

  const isDriverView = type === "chauffeur"
  const { adminData: carsData } = useAdminLists({ type: "vehicules", loop: isDriverView });


  const HEADERS = !isDriverView ? [
    { label: 'ID', filterKey: 'id' },
    { label: 'Nom & Prénoms', filterKey: 'name' },
    { label: 'Email', filterKey: 'email' },
    { label: 'Adresse', filterKey: 'address1' },
    { label: 'Téléphone', filterKey: 'telephone1' },
    { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
    { label: 'Actif', filterKey: null },
    // Add more headers as needed
  ] : [
    { label: 'ID', filterKey: 'id' },
    { label: 'Nom & Prénoms', filterKey: 'name' },
    { label: 'Email', filterKey: 'email' },
    { label: 'Adresse', filterKey: 'address1' },
    { label: 'Téléphone', filterKey: 'telephone1' },
    { label: 'Véhicule', filterKey: null },
    { label: 'DATE DE CRÉATION', filterKey: 'created_at' },
    { label: 'Actif', filterKey: null },
    // Add more headers as needed
  ]


  return (
    <TableComponent withAction={false} headerList={HEADERS} dataLength={dataList.length} isLoading={isLoading}>
      {dataList.map((item, i) => {
        return (
          <tr key={i} className="py-3 divide-y divide-gray-300">
            <td className={'cursor-pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{item.id}
            </td>
            <td className={'pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{item.name}
            </td>
            <td className={'pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{item.email}
            </td>
            <td className={'pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{item.address1}
            </td>

            <td className={'pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{item.telephone1}
            </td>

            {isDriverView &&
              <td className={'pointer min-w-[170px] max-w-[250px]'} onClick={() => {
                //onSelect(item, "edit");
              }}>
                <CarAttribution
                  items={carsData.data}
                  selectedCarId={item.vehicule_id}
                  userId={item.id}
                /* onAttributed={onCarAttributed}
                 */
                />
              </td>}

            <td className={'pointer'} onClick={() => {
              onSelect(item, "edit");
            }}>{formatDefaultDate(item.created_at)}
            </td>

            <td>
              <SwitchComponent
                checked={Boolean(item.is_actif)}
                onChange={(value) => {
                  onToggleActive(item, value);
                }}
              />{" "}
            </td>
          </tr>
        );
      })}
    </TableComponent>

  );
};
