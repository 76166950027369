import React, { useEffect, useState } from "react";
import Select from "react-select";
import { roleSplit } from "../../../utils/Functions/otherFunctions";

const ExnetTypeSelector2 = ({ showLabel = true, value, onChange, disabled }) => {
  const [types, setTypes] = useState([
    { value: "isparis", label: "Exnet Paris" },
    { value: "ismedical", label: "Exnet Medicals" },
    { value: "isservice", label: "Exnet Services" },
    { value: "isdriver", label: "Chauffeur" },
  ]);
  //const [values, setValues] = useState();

  //console.log({ value })

  useEffect(() => {
    /* const { espace } = roleSplit(value);

    if (espace) {
      let r = types.filter((type) => espace.includes(type.value));
      setValues(r);
    } else {
      let r = types.filter((type) => value.includes(type.value));
      setValues(r);
    } */
  }, [value]);

  const values = types.filter((t) => value?.includes(t.value));

  return (
    <div>
      {showLabel && (
        <label
          for="types"
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          Type
        </label>
      )}
      <Select
        options={types}
        value={values}
        onChange={(v) => {
          const selectedValues = v.map((v) => v.value);
          onChange(selectedValues);
        }}
        isMulti
        className={"text-base w-100"}
        isDisabled={disabled}
      />
    </div>
  );
};

export default ExnetTypeSelector2;
