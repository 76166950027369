import React, { useCallback, useEffect, useState } from 'react'
import { formatDefaultDate } from '../../utils/moment'
import { combineDateAndTime } from '../../utils'
import { SwitchComponent } from '../Utils/Index'
import { trierTableauParDateEtHeureSuivi } from '../../utils/Functions/otherFunctions'
import { TableComponent } from '../TableauComponent'

export default function SuiviLists({ items, onToggleActive }) {

    const [lists, setLists] = useState([])

    const convertirEnDate = (dateString, heureString, data) => {
        if (data) {
            let date = new Date(dateString).toLocaleDateString()
            let heures = new Date(dateString).toLocaleTimeString()
            const [annee, mois, jour] = date?.split('-');
            const [heure, minute] = heures?.split(':');
            return new Date(annee, mois - 1, jour, heure, minute, 0).getTime();
        } else {
            const [annee, mois, jour] = dateString?.split('-');
            const [heure, minute] = heureString?.split(':');
            return new Date(annee, mois - 1, jour, heure, minute, 0).getTime();
        }
    };

    useEffect(() => {
        if (items) {
            setLists(trierTableauParDateEtHeureSuivi(items))
            console.log(trierTableauParDateEtHeureSuivi(items))
        }
    }, [items])

    return (

        <TableComponent withAction={true} headerList={tableHead} dataLength={items.length} isLoading={false}>
            {items.map((item, i) => (
                <tr key={i}>
                    <td>{item.details}</td>
                    <td>{item.agents}</td>
                    <td>{item.chaufeur}</td>
                    <td>{formatDefaultDate(combineDateAndTime(item.date_suivi, item.heur_suivi), "DD-MM-YYYY HH:mm")}</td>
                    <td>
                        <SwitchComponent
                            checked={item.visible === 1}
                            onChange={(value) => {
                                onToggleActive(item, value);
                            }}
                        />
                    </td>
                    <td>{item.template}</td>
                </tr>
            ))}
        </TableComponent>




    )
}


const tableHead = [
    { "label": "Suivi", "filterKey": "suivi" },
    { "label": "Agent", "filterKey": "agent" },
    { "label": "Chauffeur", "filterKey": "chauffeur" },
    { "label": "Date", "filterKey": "date" },
    { "label": "Visible", "filterKey": "visible" },
    { "label": "Identifiant", "filterKey": "identifiant" }
]
