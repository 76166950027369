import React, { useEffect, useState } from "react";
import Select from "react-select";
import { roleSplit } from "../../../utils/Functions/otherFunctions";
import { clsx } from "clsx"

const ExnetTypeSelector = ({ isRequired, showLabel = true, className, value, onChange, disabled, multiple = true, placeholder }) => {
  const [types, setTypes] = useState([
    { value: "paris", label: "Exnet Paris" },
    { value: "medical", label: "Exnet Medicals" },
    { value: "service", label: "Exnet Services" },
    { value: "isdriver", label: "Chauffeur" },
  ]);
  //const [values, setValues] = useState();

  //console.log({ value })

  useEffect(() => {
    /* const { espace } = roleSplit(value);

    if (espace) {
      let r = types.filter((type) => espace.includes(type.value));
      setValues(r);
    } else {
      let r = types.filter((type) => value.includes(type.value));
      setValues(r);
    } */
  }, [value]);

  const values = multiple ? types.filter((t) => value?.includes(t.value)) : types.find((t) => value === t.value);

  return (
    <div>
      {showLabel && (
        <label
          for="types"
          className="block text-sm font-medium text-gray-900 "
        >
          Type {isRequired ? "*" : ""}
        </label>
      )}
      <Select
        options={types}
        value={values}
        onChange={(v) => {
          const selectedValues = multiple ? v.map((v) => v.value) : v
          onChange(selectedValues);
        }}
        isMulti={multiple}
        className={clsx("text-base w-100", className)}
        isDisabled={disabled}
        placeholder={placeholder}
        required={isRequired}
      />
    </div>
  );
};

export default ExnetTypeSelector;
