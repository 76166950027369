import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDisclosure from "../../hooks/useDisclosure";
import useFormValues, { useBasicFormValues } from "../../hooks/useFormValues";
import CountriesSelector from "../Utils/Selectors/CountriesSelector";
import { userService } from "../../services/user/UserService";
import ExnetTypeSelector from "../Utils/Selectors/ExnetTypeSelector";
import MenuSelector, { SubMenuSelector } from "../Utils/Selectors/MenuSelector";
import RolesSelector from "../Utils/Selectors/Roles";
import ModalUI from "../Utils/Modal/Modal";
import UsersSelector from "../Utils/Selectors/UsersSelector";
import { SwitchComponent } from "../Utils/Index";
import BillCenterSelector from "../Utils/Selectors/BillCenterSelector";
import ClientSelector from "../Utils/Selectors/ClientSelector";
import { validateUserData } from "../../utils/validators";
import { useParams } from "react-router-dom";
import { extractRoles } from "../../utils";
import Loader from "../Utils/Loader";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { getCountryNameOrCode } from "../../utils/const/countriesConstant";

const Form = ({ onSubmit, onClose, selectedData }) => {
  const { milieu: _interface } = useParams();
  const { data: userData, setFormValue, setData } = useBasicFormValues({
    roles: "user",
    menus: "",
    interface: _interface,
    type: _interface,
    user_type: "user",
    onglet_expedition: "",
    country: 'fr',
    expeditions_courses_partage: 0,
    carnet_adresses_partage: 0,
    expe_carnet_partage: 0,
    suivis_visible_to: [],
    contact_visible_to: []
  });

  const { data: metadata, setFormValue: setFormMetaValue, setMetadata } = useBasicFormValues({
    expedition_course: 0,
    carnet_adresses_partage: 0,
    expe_carnet_partage: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [allSharedToIds, setAllSharedToIds] = useState([]);

  const bothWheelsSelected = (Boolean(userData["2_roues"]) && Boolean(userData["vt"]))

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        const submitData = {
          ...userData,
          email1: userData.email,
          bill_center_id: userData.bill_center_id?.id || userData.bill_center_id,
          les_2: bothWheelsSelected
          /*  contact_visible_to: carnet_visible,
           suivis_visible_to: expedition_visible */
        };

        submitData.type = userData.interface.split(",")[0]

        const isUpdated = Boolean(userData.id)

        setValidationError(null);
        const { error: _err } = validateUserData(submitData);
        if (_err) {
          setValidationError(_err);
          return;
        }
        //delete submitData.bill_center_id
        setIsSubmitting(true);

        //console.log({ submitData })

        const request = isUpdated ? userService.update : userService.create
        const { data } = await request(submitData);

        if (data.success) {
          onSubmit?.(isUpdated ? {
            ...submitData,
            ...data.data,
            roles: selectedData.roles
          } : data.data)
        }
      } catch (error) {
        console.log(error)
        toast.error('Une erreur lors de la soumission de votre demande', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

        })
      } finally {
        setIsSubmitting(false);
      }
    },
    [userData, selectedData, bothWheelsSelected]
  );

  useEffect(() => {
    if (selectedData) {
      setData(d => ({
        ...d,
        ...selectedData,
      }));
      if (selectedData.roles) {
        const roles = extractRoles(selectedData.roles, 1)
        const interfaces = extractRoles(selectedData.roles, 2)

        setFormValue("user_type", "user")

        if (roles) {
          setFormValue("roles", roles.map(r => r.toLowerCase()).join(","))
        }

        if (interfaces) {
          setFormValue("interface", interfaces.map(r => r.toLowerCase()).join(","))
        }

        if (selectedData["2_roues"] || selectedData.les_2 || selectedData.vt) {
          setFormMetaValue("courses", 1)
        }

        /* if (selectedData.suivis_visible_to) {
          setFormMetaValue("expedition_course", 1)
        }

        if (selectedData.contact_visible_to) {
          setFormMetaValue("carnet_adresses_partage", 1)
        }

        if (selectedData.expe_carnet_partage_partage) {
          setFormMetaValue("expe_carnet_partage", 1)
        } */

      }

      if (selectedData.suivis_visible_to) {
        setFormValue("suivis_visible_to", selectedData.suivis_visible_to.map(el => el.visible_by))
      }

      if (selectedData.contact_visible_to) {
        setFormValue("contact_visible_to", selectedData.contact_visible_to.map(el => el.visible_by))
      }

      if (selectedData.suivis_visible_to && selectedData.contact_visible_to) {
        const commonIds = selectedData.suivis_visible_to.filter(item => selectedData.contact_visible_to.find((_item) => item.visible_by === _item.visible_by))
        if (commonIds) {
          setAllSharedToIds(commonIds.map((_item) => _item.visible_by))
        }
      }

      /*  if (selectedData.suivis_visible_by) {
         setFormValue("suivis_visible_by", selectedData.suivis_visible_by.map(el => el.visible_to))
       }
 
       if (selectedData.contact_visible_by) {
         setFormValue("contact_visible_by", selectedData.contact_visible_by.map(el => el.visible_to))
       } */

    }
  }, [selectedData]);


  const onBothDataSelected = useCallback((users) => {
    const ids = users.map(u => u.id);
    //console.log({ ids })
    if (ids) {
      const newIds = ids.filter(id => !userData.contact_visible_to?.includes(id) || !userData.suivis_visible_to?.includes(id));
      setFormValue("suivis_visible_to", [...userData.suivis_visible_to, ...newIds])
      setFormValue("contact_visible_to", [...userData.contact_visible_to, ...newIds])
      setAllSharedToIds(ids)
      //const allUsers
    }
    /*  v.forEach(element => {
       setFormValue("suivis_visible_to", element?.id);
       setFormValue("contact_visible_to", element.id);
     }); */
    // setFormValue("expe_carnet_partage_partage", v);
  }, [userData.contact_visible_to, userData.suivis_visible_to])

  useEffect(() => {
    if (userData?.les_2) {
      /*  setFormValue("2_roues", 1)
       setFormValue("vt", 1) */
    } else {
      /* if (userData?.["2_roues"] && userData?.["vt"]) {
        setFormValue("les_2", 1)
      } */
    }

  }, [userData])


  console.log({
    userData
  })



  return (
    <>
      <div className="p-3">
        {validationError && (
          <div className="bg-red-100 border border-gray-300 mb-2 text-red-600 px-4 py-2 rounded-md text-[13px]">
            {validationError}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6 mb-3 md:grid-cols-2">
            <div className="text-left">
              <label
                for="name"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Nom d'utilisateur*
              </label>
              <input
                type="text"
                id="name"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.name}
                onChange={(e) => setFormValue("name", e.target.value)}
              />
            </div>

            <div className="text-left">
              <label
                for="address1"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Adresse*
              </label>
              <input
                type="text"
                id="address1"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.address1}
                onChange={(e) => setFormValue("address1", e.target.value)}
              />
            </div>

            <div className="text-left">
              <label
                for="email2"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Complément d'adresse
              </label>
              <input
                type="text"
                id="address2"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                value={userData.email2}
                onChange={(e) => setFormValue("email2", e.target.value)}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="city"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Ville*
              </label>
              <input
                type="text"
                id="city"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.city}
                onChange={(e) => setFormValue("city", e.target.value)}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="postal_code"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Code Postal*
              </label>
              <input
                type="text"
                id="postal_code"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.postal_code}
                onChange={(e) => setFormValue("postal_code", e.target.value)}
              />
            </div>

            <div className="text-left">
              <CountriesSelector
                selectedValue={userData.country}
                onSelect={(v) => {
                  setFormValue("country", v.value);
                }}
              // isDisabled={!editable}
              />
            </div>

            <div className="text-left">
              <label
                for="telephone1"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Téléphone 1*
              </label>
              {/* <input
                type="text"
                id="telephone1"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.telephone1}
                onChange={(e) => setFormValue("telephone1", e.target.value)}
              /> */}
              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(userData?.country), true) ?? 'fr'}
                defaultValue={userData.telephone1}
                value={userData.telephone1}
                onChange={(value, country, e, formattedValue) => { setFormValue("telephone1", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={true}
              />
            </div>

            <div className="text-left">
              <label
                for="telephone2"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Téléphone 2
              </label>
              {/* <input
                type="text"
                id="telephone2"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                value={userData.telephone2}
                onChange={(e) => setFormValue("telephone2", e.target.value)}
              /> */}
              <PhoneInput
                country={getCountryNameOrCode(getCountryNameOrCode(userData?.country), true) ?? 'fr'}
                defaultValue={userData.telephone2}
                value={userData.telephone2}
                onChange={(value, country, e, formattedValue) => { setFormValue("telephone2", formattedValue) }}
                inputClass={'form-controle'}
                countryCodeEditable={true}
                disableDropdown={false}
              />
            </div>

            <div className="text-left">
              <label
                for="email1"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Email*
              </label>
              <input
                type="text"
                id="email1"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.email}
                onChange={(e) => setFormValue("email", e.target.value)}
              />
            </div>
            <div className="text-left">
              <ExnetTypeSelector
                value={userData.interface?.split(",")}
                onChange={(v) => {
                  setFormValue("interface", v.join(","));
                }}
              // disabled={Boolean(selectedData)}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="customer_id"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Choix du client
              </label>
              {/* <UsersSelector
                showLabel={false}
                profil={"user"}
                selectedValue={userData.customer_id}
                onSelect={(v) => {
                  setFormValue("customer_id", v.id);
                }}
              /> */}
              <ClientSelector
                selectedValue={userData.customer_id}
                showLabel={false}
                profil={"customer"}
                onSelect={(v) => {
                  setSelectedCustomer(v)
                  setFormValue("customer_id", v.value)
                }}
              />
            </div>

            <div className="text-left">
              <label
                htmlFor="bill_center_id"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Choix du centre de facturation
              </label>

              <BillCenterSelector
                adminRouting={true}
                multiple
                type={selectedCustomer?.type || 'all'}
                selectedValue={userData.bill_center_id
                }
                onSelect={(values) => {
                  console.log({ values })
                  setFormValue("bill_center_id", values.map(v => v.value));
                }}
                user={userData.customer_id}
              />
            </div>

            <div className="col-span-2 text-left ">
              <label
                for="entreprise_physique"
                className="block mb-1 text-sm font-medium text-gray-900 "
              >
                Nom Direction / Service / Département physique
              </label>
              <input
                type="text"
                id="entreprise_physique"
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-blue-100"
                required
                value={userData.entreprise_physique}
                onChange={(e) => setFormValue("entreprise_physique", e.target.value)}
              />
            </div>


            {/* <div className="text-left">
              <RolesSelector
                value={userData.roles}
                onChange={(v) => {
                  setFormValue("roles", v);
                }}
              />
            </div>

            <div className="text-left">
              <MenuSelector
                value={userData.menus}
                onChange={(v) => {
                  setFormValue("menus", v);
                }}
              />
            </div>

            <div className="text-left">
              <SubMenuSelector
                value={userData.sous_menus}
                onChange={(v) => {
                  setFormValue("sous_menus", v);
                }}
              />
            </div> */}
          </div>

          <div className="w-full h-[1px] bg-gray-300 my-2" />

          <div className="w-full mb-3">
            <div className="font-semibold text-[13px] text-gray-700 mb-2">
              {" "}
              Les Prestations
            </div>
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center justify-between mb-1 ml-2">
                <label
                  htmlFor="import"
                  className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                >
                  Import
                </label>
                <SwitchComponent
                  id="import"
                  label="import"
                  checked={userData.import === 1}
                  onChange={(checked) => {
                    setFormValue("import", checked ? 1 : 0);
                  }}

                />
              </div>
              <div className="flex items-center justify-between mb-1 ml-2">
                <label
                  htmlFor="export"
                  className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                >
                  Export
                </label>
                <SwitchComponent
                  id="export"
                  label="export"
                  checked={userData.export === 1}
                  onChange={(checked) => {
                    setFormValue("export", checked ? 1 : 0);
                  }}
                />
              </div>
              <div>
                <div className="flex items-center justify-between mb-1 ml-2">
                  <label
                    htmlFor="courses"
                    className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                  >
                    Courses
                  </label>
                  <SwitchComponent
                    id="courses"
                    label="courses"
                    checked={metadata.courses === 1}
                    onChange={(checked) => {
                      setFormMetaValue("courses", checked ? 1 : 0);
                    }}
                  />
                </div>
                {Boolean(metadata.courses) && (
                  <div className="mt-2">
                    <div className="flex items-center justify-between mb-1 ml-2">
                      <label
                        htmlFor="2_roues"
                        className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        2 Roues
                      </label>
                      <SwitchComponent
                        id="2_roues"
                        label="2_roues"
                        checked={userData["2_roues"] === 1}
                        onChange={(checked) => {
                          setFormValue("2_roues", checked ? 1 : 0);
                          if (checked && userData["vt"]) {
                            setFormValue("les_2", 1)
                          }
                        }}
                      />
                    </div>

                    <div className="flex items-center justify-between mb-1 ml-2">
                      <label
                        htmlFor="vt"
                        className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        VT
                      </label>
                      <SwitchComponent
                        id="vt"
                        label="vt"
                        checked={userData.vt === 1}
                        onChange={(checked) => {
                          setFormValue("vt", checked ? 1 : 0);
                          if (checked && userData["2_roues"]) {
                            setFormValue("les_2", 1)
                          }
                        }}
                      />
                    </div>

                    <div className="flex items-center justify-between mb-1 ml-2">
                      <label
                        htmlFor="les_2"
                        className="mr-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        les_2
                      </label>
                      <SwitchComponent
                        id="les_2"
                        label="les_2"
                        checked={bothWheelsSelected}
                        onChange={(checked) => {
                          //setFormValue("les_2", checked ? 1 : 0);
                          if (checked) {
                            setFormValue("2_roues", 1)
                            setFormValue("vt", 1)
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full h-[1px] bg-gray-300 my-2" />

          <div>
            <div className="font-semibold text-[13px] mb-2">Trackings</div>
            <div className="flex flex-wrap items-center justify-between ml-2">
              <div className="flex items-center justify-between mb-3">
                <label
                  htmlFor="mini"
                  className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
                >
                  Mini
                </label>
                <SwitchComponent
                  id="mini"
                  label="Trackings mini"
                  checked={userData.mini === 1}
                  onChange={(checked) => {
                    setFormValue("mini", checked ? 1 : 0);
                  }}
                />
              </div>
              <div className="flex items-center justify-between mb-3">
                <label
                  htmlFor="full"
                  className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
                >
                  Full
                </label>
                <SwitchComponent
                  id="full"
                  label="Trackings Full"
                  checked={userData.full === 1}
                  onChange={(checked) => {
                    setFormValue("full", checked ? 1 : 0);
                  }}
                />
              </div>
              <div className="flex items-center justify-between w-20 mb-1 ml-2" />
            </div>{" "}
          </div>

          <div className="w-full h-[1px] bg-gray-300 my-2" />
          <div>
            <div className="font-semibold text-[13px] mb-2">Partage</div>

            <div className="grid gap-6 mb-3 md:grid-cols-3">
              <div>
                <div className="flex items-center mb-3">
                  <label
                    htmlFor="expeditions_courses_partage"
                    className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
                  >
                    Expéditions/Courses
                  </label>
                  <div className="mr-4">
                    <SwitchComponent
                      id="expeditions_courses_partage"
                      label="expeditions_courses_partage"
                      checked={userData.expeditions_courses_partage === 1}
                      onChange={(checked) => {
                        setFormValue("expeditions_courses_partage", checked ? 1 : 0);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  {userData.expeditions_courses_partage === 1 ? (
                    <UsersSelector
                      isMulti={true}
                      showLabel={false}
                      profil={"user"}
                      selectedValue={userData.suivis_visible_to}
                      onSelect={(v) => {
                        const expeditionsVisibleTo = v.map(_item => _item.id)
                        setFormValue("suivis_visible_to", expeditionsVisibleTo);
                      }}
                    />
                  ) : null}
                </div>
              </div>

              <div>
                <div className="flex items-center mb-3">
                  <label
                    htmlFor="carnet_adresses_partage"
                    className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
                  >
                    Carnet d'adresses
                  </label>
                  <div className="mr-8">
                    <SwitchComponent
                      id="carnet_adresses_partage"
                      label="carnet_adresses_partage"
                      checked={userData.carnet_adresses_partage === 1}
                      onChange={(checked) => {
                        const value = checked ? 1 : 0;
                        setFormValue("carnet_adresses_partage", value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  {userData.carnet_adresses_partage === 1 ? (
                    <UsersSelector
                      isMulti={true}
                      showLabel={false}
                      profil={"user"}
                      selectedValue={userData.contact_visible_to}
                      onSelect={(v) => {

                        const carnetVisibleToIds = v.map(_item => _item.id)
                        setFormValue("contact_visible_to", carnetVisibleToIds);
                      }}
                    />
                  ) : null}
                </div>
              </div>

              <div>
                <div className="flex items-center mb-3">
                  <label
                    htmlFor="expe_carnet_partage"
                    className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
                  >
                    Expé & Carnet
                  </label>
                  <div className="mr-14">
                    <SwitchComponent
                      id="expe_carnet_partage"
                      label="expe_carnet_partage"
                      checked={userData.expe_carnet_partage === 1}
                      onChange={(checked) => {
                        const value = checked ? 1 : 0
                        setFormValue("expe_carnet_partage", value);
                        if (Boolean(value)) {
                          if (userData.carnet_adresses_partage !== 1) {
                            setFormValue("carnet_adresses_partage", 1);
                          }
                          if (userData.expeditions_courses_partage !== 1) {
                            setFormValue("expeditions_courses_partage", 1);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  {userData.expe_carnet_partage === 1 ? (
                    <UsersSelector
                      isMulti={true}
                      showLabel={false}
                      profil={"user"}
                      selectedValue={allSharedToIds}
                      onSelect={onBothDataSelected}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-[1px] bg-gray-300 my-2" />

          <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center justify-between mb-3">
              <label
                htmlFor="acces_aux_archives"
                className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
              >
                Accès aux archives
              </label>
              <SwitchComponent
                id="acces_aux_archives"
                label="acces_aux_archives"
                checked={userData.acces_aux_archives === 1}
                onChange={(checked) => {
                  setFormValue("acces_aux_archives", checked ? 1 : 0);
                }}
              />
            </div>
            <div className="flex items-center justify-between mb-3">
              <label
                htmlFor="calculatrice"
                className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
              >
                Calculatrice (Utilisateur GOYARD)
              </label>
              <SwitchComponent
                id="calculatrice"
                label="Calculatrice"
                checked={userData.calculatrice === 1}
                onChange={(checked) => {
                  setFormValue("calculatrice", checked ? 1 : 0);
                }}
              />
            </div>

            <div className="flex items-center justify-between mb-3">
              <label
                htmlFor="lettre_info"
                className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
              >
                Lettre d'info / lettre_info
              </label>
              <SwitchComponent
                id="lettre_info"
                label="lettre_info"
                checked={userData.lettre_info === 1}
                onChange={(checked) => {
                  setFormValue("lettre_info", checked ? 1 : 0);
                }}
              />
            </div>

            <div className="flex items-center justify-between mb-3">
              <label
                htmlFor="tracking_mail"
                className="mr-3 text-sm font-medium text-gray-900 cursor-pointer"
              >
                Recevoir mail de  Tracking
              </label>
              <SwitchComponent
                id="tracking_mail"
                label="tracking_mail"
                checked={userData.tracking_mail === 1}
                onChange={(checked) => {
                  setFormValue("tracking_mail", checked ? 1 : 0);
                }}
                checkedColor="#098721"
              />
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <div
              type="button"
              onClick={() => onClose?.()}
              className="bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-3"
            >
              Annuler
            </div>
            <button
              type="submit"
              className={`${isSubmitting ? "cursor-not-allowed" : "cursor-pointer"} text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}
              disabled={isSubmitting}
            >
              <span className="mr-1">Enregistrer</span>
              {isSubmitting && <Loader />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default function UsersForm({ withoutModal, onSubmit, isOpen, onClose, selectedData }) {


  return (
    <>
      <ModalUI
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        title={`${selectedData ? "Modifier l'utilisateur" : "Créer un utilisateur"}`}
      >
        <Form
          onSubmit={(result) => {
            onClose();
            onSubmit?.(result);
          }}
          selectedData={selectedData}
          onClose={onClose}
        />
      </ModalUI>
    </>
  );
}
