import { is } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const MENUS_OPTIONS = [
  { value: "pending", label: "Expéditions en attente" },
  { value: "active", label: "Expéditions en cours" },
  { value: "delivered", label: "Expéditions livrées" },
  { value: "cancelled", label: "Expéditions annulées" },
  { value: "factured", label: "Expéditions facturées" },
  { value: "tobeArchived", label: "Expéditions à archiver" },
  { value: "archived", label: "Expéditions archivées" },
  { value: "manifest", label: "Manifest" },
  /* Liste des factures */
  { value: "facture", label: "Facture" },
]


export const ADMIN_MENUS = [
  { value: "clients", label: "Clients" },
  { value: "centers", label: "Centres de facturation" },
  { value: "utilisateurs", label: "Utilisateurs" },
  { value: "contacts", label: "Contacts" },
  { value: "fournisseurs", label: "Fournisseurs" },
  { value: "histories", label: "Le 24 Heures" },
  { value: "contacts", label: "Contacts" },
  { value: "fuels", label: "Fuels" },
  { value: "facturationCodes", label: "Codes de facturation" },
  { value: "statsCourses", label: "Courses" },
  { value: "statsFreight", label: "Freight" },
  { value: "templates", label: "Tracking templates" },
  { value: "transportTypes", label: "Type de transport" },
  { value: "vehicules", label: "Véhicules" },
  { value: "employees", label: "Salariés" },
  { value: "admins", label: "Admin" },
  { value: "ops", label: "Ops" },
  { value: "drivers", label: "Chauffeurs" },
]

const MenuSelector = ({ showLabel = true, value, onChange, required, type }) => {
  const [options, setOptions] = useState(MENUS_OPTIONS);

  const values = options.filter((type) => value?.includes(type.value));


  useEffect(() => {
    if (type === "admin") {
      setOptions([...ADMIN_MENUS, ...MENUS_OPTIONS])
    } else {
      setOptions(MENUS_OPTIONS)
    }
  }, [type])

  return (
    <div>
      {showLabel && (
        <label
          for="menus"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Menus {required && "*"}
        </label>
      )}
      <Select
        options={options}
        value={values}
        onChange={(v) => {
          const selectedValues = v.map((v) => v.value);
          onChange(selectedValues);
        }}
        isMulti
        className="w-100 text-base"
      />
    </div>
  );
};

export const SubMenuSelector = ({ showLabel = true, value, onChange }) => {
  const [options, setOptions] = useState([
    { value: "details", label: "Détails" },
    { value: "fichiers", label: "Fichiers" },
    { value: "manifest", label: "Manifest" },
    { value: "mails", label: "Mails" },
    { value: "tracking", label: "Tracking" },
    { value: "pod", label: "POD" },
    { value: "comments", label: "Commentaire" },
    { value: "facture", label: "Facturations" },
  ]);

  const values = options.filter((type) => value?.includes(type.value));

  return (
    <div>
      {showLabel && (
        <label
          for="subMenus"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Détails expédition
        </label>
      )}
      <Select
        options={options}
        value={values}
        onChange={(v) => {
          const selectedValues = v.map((v) => v.value);
          onChange(selectedValues);
        }}
        isMulti
        className="w-100 text-sm"
      />
    </div>
  );
};

export const CourseTypeSelector = ({
  label = "Course à course",
  value,
  onChange,
}) => {
  const [options, setOptions] = useState([
    { value: "2_wheels", label: "2 Roues" },
    { value: "'4_wheels'", label: "4 Roues" },
  ]);

  const selectedValue = options.find((c) => c?.value == value);

  return (
    <div>
      <label
        htmlFor="courses"
        class="block mb-2 text-sm font-medium text-gray-900 "
      >
        {label}
      </label>
      <Select
        options={options}
        value={selectedValue}
        onChange={(v) => {
          onChange(v);
        }}
        className="w-100"
      />
    </div>
  );
};

export default MenuSelector;
