import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backgroundOps from "../assets/img/v1/18776164_6032890.svg";
import backgroundAdmin from "../assets/img/v1/18776165_6032877.jpg";
import backgroundClient from "../assets/img/v1/18776140_6030752.svg";

import BgNatureImg from "../assets/img/nature.jpg";
import BgNatureImg3 from "../assets/img/nature3.jpg";
import BgNatureImg4 from "../assets/img/nature4.jpg";
import BgNatureImg5 from "../assets/img/nature5.jpg";
import { isSelectedYears, urlSplit } from "../utils/Functions/otherFunctions";
import DecemnerImg_1 from './../assets/img/december/december_1.jpg'
import DecemnerImg_3 from './../assets/img/december/december_3.jpg'
import DecemnerImg_4 from './../assets/img/december/december_4.jpg'
import DecemnerImg_5 from './../assets/img/december/december_5.jpg'
import DecemnerImg_6 from './../assets/img/december/december_6.jpg'
import DecemnerImg_7 from './../assets/img/december/december_7.jpg'
import DecemnerImg_8 from './../assets/img/december/december_8.jpg'
import DecemnerImg_9 from './../assets/img/december/december_9.jpg'
import DecemnerImg_10 from './../assets/img/december/december_10.jpg'
import DecemnerImg_11 from './../assets/img/december/december_11.jpg'
import NewYearImg_1 from './../assets/img/newyears/newyers_1.jpeg'
import NewYearImg_2 from './../assets/img/newyears/newyers_2.jpeg'
import NewYearImg_3 from './../assets/img/newyears/newyers_3.jpeg'
import NewYearImg_4 from './../assets/img/newyears/newyers_4.jpeg'
import NewYearImg_5 from './../assets/img/newyears/newyers_5.jpeg'
import NewYearImg_6 from './../assets/img/newyears/newyers_6.jpeg'
import NewYearImg_7 from './../assets/img/newyears/newyers_7.jpeg'
import NewYearImg_8 from './../assets/img/newyears/newyers_8.jpeg'

const tableImg = [BgNatureImg, BgNatureImg3, BgNatureImg4, BgNatureImg5, backgroundAdmin];
const decemberImg = [DecemnerImg_1, DecemnerImg_3, DecemnerImg_4, DecemnerImg_5, DecemnerImg_6, DecemnerImg_7, DecemnerImg_8, DecemnerImg_9, DecemnerImg_10, DecemnerImg_11, ];
const newYearsImage = [NewYearImg_1, NewYearImg_2, NewYearImg_3, NewYearImg_4, NewYearImg_5, NewYearImg_6, NewYearImg_7, NewYearImg_8];

// generate a random number between 0 and 3
const randomImage = () => {
  console.log(isSelectedYears(0),isSelectedYears(11))
  if (isSelectedYears(0)) {
    return newYearsImage[Math.floor(Math.random() * 7)];
  } else if (isSelectedYears(11)) {
    return decemberImg[Math.floor(Math.random() * 10)];
  }
  else {
    return tableImg[Math.floor(Math.random() * 3)];
  }
}



function PasswordResetSentPage() {
  const {role:UserRole} = urlSplit()
  const [backgroundState, setBackgroundState] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  useEffect(() => {
    setBackgroundState(randomImage());
  }, []);
  return (
    <div
      class="main-wrapper account-wrapper bg-wrapper"
      style={{
        backgroundImage: `url(${backgroundState})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div class="account-page h-screen d-flex flex-col justify-center items-center pt-5">
        <div class="account-center mt-5">
          <div class="account-logo d-flex align-middle justify-center content-center mx-2"></div>
          <div class="account-box w-screen">
            <div class="login-header">
              <h3 className="text-center my-3">
                Mail de réinitialisation du mot de passe envoyé!
              </h3>
              <h4 className="text-center my-3">
                Veuillez vérifier votre courrier électronique pour obtenir des
                instructions supplémentaires.
              </h4>

              <div className="form-group text-center">
                <button
                  type="button"
                  className="btn btn-primary account-btn"
                  onClick={() => UserRole==="admin"? navigate("/admin/auth"):navigate("/")}
                >
                  Retour à l'accueil
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetSentPage;
