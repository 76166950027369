import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Head from "../components/HeadComponent/Head.jsx";
import { authController } from "../controller/auth/authController.js";
import { validateEmail } from "../utils/ExpressionReguliere";
import passwordSvg from "./../assets/img/icon/lock-icon-02.svg";
import emailSvg from "./../assets/img/icon/lock-icon-01.svg";
import backgroundOps from "../assets/img/v1/18776164_6032890.svg";
import backgroundAdmin from "../assets/img/v1/18776165_6032877.jpg";
import backgroundClient from "../assets/img/v1/18776140_6030752.svg";
import logoParis from "./../assets/img/exnext-logo/paris.png";
import logoMedical from "./../assets/img/exnext-logo/medical.png";
import logoService from "./../assets/img/exnext-logo/service.png";
import axios from "axios";
import { roleTable } from "../utils/SpaceData.js";

import BgNatureImg from "../assets/img/nature.jpg";
import BgNatureImg3 from "../assets/img/nature3.jpg";
import BgNatureImg4 from "../assets/img/nature4.jpg";
import BgNatureImg5 from "../assets/img/nature5.jpg";
import { isSelectedYears, urlSplit } from "../utils/Functions/otherFunctions";
import DecemnerImg_1 from './../assets/img/december/december_1.jpg'
import DecemnerImg_3 from './../assets/img/december/december_3.jpg'
import DecemnerImg_4 from './../assets/img/december/december_4.jpg'
import DecemnerImg_5 from './../assets/img/december/december_5.jpg'
import DecemnerImg_6 from './../assets/img/december/december_6.jpg'
import DecemnerImg_7 from './../assets/img/december/december_7.jpg'
import DecemnerImg_8 from './../assets/img/december/december_8.jpg'
import DecemnerImg_9 from './../assets/img/december/december_9.jpg'
import DecemnerImg_10 from './../assets/img/december/december_10.jpg'
import DecemnerImg_11 from './../assets/img/december/december_11.jpg'
import NewYearImg_1 from './../assets/img/newyears/newyers_1.jpeg'
import NewYearImg_2 from './../assets/img/newyears/newyers_2.jpeg'
import NewYearImg_3 from './../assets/img/newyears/newyers_3.jpeg'
import NewYearImg_4 from './../assets/img/newyears/newyers_4.jpeg'
import NewYearImg_5 from './../assets/img/newyears/newyers_5.jpeg'
import NewYearImg_6 from './../assets/img/newyears/newyers_6.jpeg'
import NewYearImg_7 from './../assets/img/newyears/newyers_7.jpeg'
import NewYearImg_8 from './../assets/img/newyears/newyers_8.jpeg'
import { isStrongPassword } from "../utils/validators.js";

const tableImg = [BgNatureImg, BgNatureImg3, BgNatureImg4, BgNatureImg5, backgroundAdmin];
const decemberImg = [DecemnerImg_1, DecemnerImg_3, DecemnerImg_4, DecemnerImg_5, DecemnerImg_6, DecemnerImg_7, DecemnerImg_8, DecemnerImg_9, DecemnerImg_10, DecemnerImg_11,];
const newYearsImage = [NewYearImg_1, NewYearImg_2, NewYearImg_3, NewYearImg_4, NewYearImg_5, NewYearImg_6, NewYearImg_7, NewYearImg_8];

// generate a random number between 0 and 3
const randomImage = () => {
  console.log(isSelectedYears(0), isSelectedYears(11))
  if (isSelectedYears(0)) {
    return newYearsImage[Math.floor(Math.random() * 7)];
  } else if (isSelectedYears(11)) {
    return decemberImg[Math.floor(Math.random() * 10)];
  }
  else {
    return tableImg[Math.floor(Math.random() * 3)];
  }
}




function ResetPassword() {
  const { role: UserRole } = urlSplit()
  const { role, milieu } = useParams();
  const [backgroundState, setBackgroundState] = useState(backgroundOps);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  useEffect(() => {
    setBackgroundState(randomImage());
  }, []);

  const resetPassword = async () => {
    if (validateEmail(email)) {
      if (password.length >= 8 && password === confirmPassword) {
        try {
          const { error } = isStrongPassword(password)

          if (error) {
            toast.error(error, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/reset-password`,
              {
                email: email,
                token: token,
                password: password,
                password_confirm: confirmPassword,
                ismobile: false,
              }
            );
            if (response.data.success) {
              toast.success("Mot de passe réinitialisé avec succès", {
                position: "top-right",
                theme: "light",
              });
              if (UserRole === 'admin') {
                navigate(`/admin/auth`); // Redirect to login page
              } else {
                navigate(`/${role}/${milieu}/auth`); // Redirect to login page
              }

            } else {
              toast.error("Échec de la réinitialisation du mot de passe", {
                position: "top-right",
                theme: "light",
              });
            }
          }

        } catch (error) {
          console.error(error);
        }
      } else {
        toast.warning(
          "Le mot de passe doit contenir au moins 8 caractères et correspondre à la confirmation",
          {
            position: "top-right",
            theme: "light",
          }
        );
      }
    } else {
      toast.warning("Email invalide", {
        position: "top-right",
        theme: "light",
      });
    }
  };

  return (
    <>
      <Head espace={milieu}>
        <title>{`Exnet | ${role}-Reset password`}</title>
      </Head>
      <div
        class="main-wrapper account-wrapper bg-wrapper"
        style={{
          backgroundImage: `url(${backgroundState})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="account-page h-screen d-flex flex-col justify-center items-center pt-5">
          <div class="account-center mt-5">

            <div className="account-box">
              <div class="account-logo d-flex align-middle justify-center content-center mx-2">
                <a href="#">
                  <img
                    className="mx-5"
                    src={
                      milieu === "medical"
                        ? logoMedical
                        : milieu === "service"
                          ? logoService
                          : logoParis
                    }
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="login-header">
                <h3 className="text-center">
                  Réinitialisation du mot de passe
                </h3>
              </div>
              <form className="form-signin mt-5">
                <div className="form-group">
                  <input
                    type="password"
                    className="w-full bg-transparent border border-gray-500 rounded-lg outline-none form-control"
                    placeholder="Nouveau mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="profile-views">
                    <img src={passwordSvg} alt="" />
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="w-full bg-transparent border border-gray-500 rounded-lg outline-none form-control"
                    placeholder="Confirmer le mot de passe"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <span className="profile-views">
                    <img src={passwordSvg} alt="" />
                  </span>
                </div>
                <div className="form-group text-center">
                  <button
                    type="button"
                    className="btn btn-primary account-btn"
                    onClick={resetPassword}
                  >
                    Réinitialiser le mot de passe
                    <i className="fas fa-arrow-right ms-1"></i>
                  </button>
                  <button

                    className="px-5 btn btn-sm"
                    onClick={() => UserRole === "admin" ? navigate("/admin/auth") : navigate("/")}
                    style={{ border: "none" }}
                  >
                    Retour à l'accueil
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
